<div class="sidebar-widget" id="company">              
         <div class="col-sm-12"> 
            <img alt="Featured Job" class="img-responsive" src="../../assets/images/Telemetry.jpg">
            <br>         
             <h3>Contact Us</h3>
             <i class="glyphicon glyphicon-log-in"></i> &nbsp;&nbsp; <strong>4715D Sunset Blvd | Lexington SC 29072</strong><br>
             <i class="fa fa-phone"></i><strong>&nbsp;&nbsp;<style>p { font-size: 16px;}</style>(803)-528-4567</strong><br>
             <i class="fa fa-fax"></i><strong>&nbsp;&nbsp;(803) 996-1055</strong><br>
             <i class="fa fa-envelope"></i><strong>&nbsp;&nbsp;staffing@lifelinehcp.com</strong>

         </div>
</div>  
