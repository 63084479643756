import { HttpClient, HttpEvent } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ContactRequest } from 'src/app/Service Model/Apply';
import { LifelineResponse } from 'src/app/Service Model/Response';
import { JobPostService } from 'src/app/Services/job-post.service';

@Component({
  selector: 'app-global-apply',
  templateUrl: '../views/global-apply.component.html',

})
export class GlobalApplyComponent implements OnInit{
  isLoad : boolean =false;
  SubmitMessage:string='';
  SubmitClass:string='';
  formData = new FormData();
  ContactRequest :ContactRequest= new ContactRequest();
  Result:LifelineResponse=new LifelineResponse();
  uploadState:boolean=false;
  UploadedFileName:string='';
   constructor(private job:JobPostService) { }
 
   ngOnInit(): void {
    window.scrollTo(0,0);
    }
  
 form = new FormGroup({
    fname: new FormControl('', [Validators.required, Validators.minLength(3)]),
    lname: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email,Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
    discipline:new FormControl('', [Validators.required]),
    phone:new FormControl(''),
    
  });

   
  get f(){

    return this.form.controls;
  }


  uploadFile(files:any){
   
    if (files.length === 0) {
      this.uploadState=false;     
    }
    else
    {
    let fileToUpload = <File>files[0];
    this.formData = new FormData();
    this.formData.append('file', fileToUpload, fileToUpload.name);
    this.uploadState=true;   
   }  
  
  }


   
  submit(){   
    this.isLoad =true;
    if( this.uploadState){
      this.job.UploadFile(this.formData).subscribe((x:LifelineResponse)=>{     
        this.UploadedFileName=x.Message;  
        this.ContactRequest.Model.Attachemnt=x.Message;
       })
    }


    this.ContactRequest.Model.JobTitle='';
    this.ContactRequest.Model.FirstName=this.form.value['fname'];
    this.ContactRequest.Model.LastName=this.form.value['lname'];
    this.ContactRequest.Model.EmailAddress=this.form.value['email'];
    this.ContactRequest.Model.ContactNumber=this.form.value['phone'];   
    this.ContactRequest.Model.Discipline=this.form.value['discipline'];
    if( this.uploadState){
      setTimeout(() => this.AfterAssignedFileName(), 10000);
    }
    else{
      this.AfterAssignedFileName();
    }
  
   
     
  }

  AfterAssignedFileName(){
    
    console.log(this.isLoad);
    this.job.ApplyJob(this.ContactRequest).subscribe((x:LifelineResponse)=>{
      this.Result=x;      
      
      console.log(this.Result);
      if(this.Result.Status==1){
        this.SubmitMessage="Request Sent Successfully !!!";
        this.SubmitClass="success";
        this.isLoad =false;
        this.form.reset();
      }
      else{
        this.SubmitMessage="Sorry !!! Something Went Wrong";
        this.SubmitClass="danger";
        this.isLoad =false;
      }
    })  
  }
   
}
