<div id="form-loader" *ngIf="isLoad">
  <i class="fa fa-cog fa-4x fa-spin"></i>
</div>

<section id="title">
  <div class="container">
    <div class="row">
      <div class="col-sm-12 text-center">
        <h1>Find a Job</h1>
        <h4>There is no better place to start</h4>
      </div>
    </div>
  </div>
</section>

<!-- ============ TITLE END ============ -->
<!-- ============ JOBS START ============ -->

<section id="jobs">
  <div class="container">
    <div class="row">

      <!-- Jobs Filters -->
      <div class="col-sm-3">

        <div class="featured-job">
          <div class="row" style="padding: 10px;">

            <div class="col-sm-12">
              <label>State</label>
              <select [(ngModel)]="selectedStateLevel" (change)="onChangeState()" class="form-control">
                <option value="-1" disabled> - Select State - </option>
                <option [ngValue]="{Id:0}">All</option>
                <option *ngFor="let item of stateModel" [ngValue]="item">{{item.State}}, {{item.ShortCode}}</option>
              </select>
              <br>
            </div>


          </div>
        </div>
      </div>
      <!-- Jobs Filters End -->
      <div class="col-sm-9">

        <div class="jobs"  *ngFor="let item of filteredJobs">
          <!-- Job offer 1 -->
          <a class="featured applied" (click)="goToApply(item)">
            <div class="row" style="text-align:center;">

              <div class="col-lg-5 col-md-5 col-sm-7 col-xs-12">
                <div class="job-title">
                  <h5>{{item.JobTitle}}</h5> 
                </div>                               
                <hr style="margin: 10px;">
              <p class="badge part-time">{{item.JobType}}</p> &nbsp;
              <p class="badge freelance">{{item.JobDuration}}</p>
              </div>
              <div class="col-lg-4 col-md-4 col-sm-5 col-xs-12 ">
                <p><strong><span class="job-location"></span>{{item.City}}, {{item.StateText}}</strong></p>
                <p><strong>{{item.Country}}</strong></p>
                
              </div>
              <div class="col-lg-2 col-md-2 job-type text-center">               
                <p class="badge apply">Apply Now</p>
              </div>
            </div>
          </a>         
        </div>
        <div class="jobs"  *ngIf="filteredJobs.length==0" style="margin-top:40px;">
          <!-- Job offer 1 -->
          <a class="featured applied">
            <div class="row">              
              <div class="col-md-12" style="text-align:center;">
                <p class="badge temporary">No Jobs Found</p>               
              </div>
              
            </div>
          </a>         
        </div>
      </div>
    </div>
  </div>
</section>

<!-- ============ JOBS END ============ -->
