<!-- ============ SLIDES START ============ -->

<div id="slider" class="sl-slider-wrapper">

  <div class="sl-slider">
    <div class="sl-slide" data-orientation="horizontal" data-slice1-rotation="-25" data-slice2-rotation="-25" data-slice1-scale="2" data-slice2-scale="2">
      <div class="sl-slide-inner">
        <div class="bg-img bg-img-video"></div>
        <div class="tint"></div>
        <!-- <div class="slide-content">
          <h2>WHAT WE OFFER</h2>
          <p class="p2white" style="margin: 0px;">
            Lifeline provides highly qualified personnel resources to hospitals and other partners in the ever-changing medical field since 2008.
          </p>
          <p class="p2white" style="margin: 0px;">
            Our expertise and recruiting professionals ensure quality in every aspect of the healthcare staffing process and with over a decade of experience we have been able to streamline the hiring process, thereby removing the stress of unfilled positions.
          </p>
          <p style="margin: 18px;"><a (click)="OnClickpage()" class="btn btn-lg btn-default">Contact Us</a></p>
          <div class="alert alert-warning  visible-md-block visible-lg-block visible-xl-block" style="margin: 15px;">
            <span class="covid-text"><b class="highlight">COVID-19</b>&nbsp; has placed unforeseen demands on hospital and medical facilities around the country and Lifeline is here to help you address the surging need for the right staff in these critical roles. Our Allied Health group can quickly and accurately find the right talent for your lab specialists so that your mission to provide the best health care can continue unimpeded.</span>
          </div>
        </div> -->
      </div>
    </div>

    <!-- <div class="sl-slide" data-orientation="horizontal" data-slice1-rotation="3" data-slice2-rotation="3" data-slice1-scale="2" data-slice2-scale="1">
      <div class="sl-slide-inner">
        <div class="bg-img bg-img-3"></div>
        <div class="tint"></div>
        <div class="slide-content">
          <h2>WHAT WE DO</h2>
          <p class="p2white" style="margin: 0px;">Lifeline utilizes a best in class recruiting system and are adding to our database of allied health and clinical professionals on a daily basis.<br> This allows us to find the most qualified and best fit candidate in the shortest amount of time. With a nationwide partner <br>network, we can address the needs of any US based hospital or health facility regardless of location.</p>
          <p><a (click)="OnClickpage()" class="btn btn-lg btn-default">Contact Us</a></p>
        </div>

      </div>

    </div> -->

    <!-- <div class="sl-slide" data-orientation="vertical" data-slice1-rotation="-5" data-slice2-rotation="25" data-slice1-scale="2" data-slice2-scale="1">
      <div class="sl-slide-inner">
        <div class="bg-img bg-img-4"></div>
        <div class="tint"></div>
        <div class="slide-content">
          <h2>WHAT WE PROVIDE</h2>
          <p class="p2white" style="margin: 0px;">
            Lifeline is able to provide a full range of specialists in the following allied health areas:
            Medical Lab Technicians,<br>
            Medical Lab Scientists,
            Medical Lab Assistant,
            Phlebotomists,
            Lab Director/Manager
          </p>
          <p> <a (click)="OnClickpage()" class="btn btn-lg btn-default">Contact Us</a></p>
        </div>
      </div>
    </div> -->

  </div>

  <!-- <nav id="nav-arrows" class="nav-arrows">
    <span class="nav-arrow-prev">Previous</span>
    <span class="nav-arrow-next">Next</span>
  </nav> -->

  <!-- <nav id="nav-dots" class="nav-dots">
    <span class="nav-dot-current"></span>
    <span></span>
    <span></span>
    <span></span> 
  </nav> -->

</div>
<br>



<!-- ============ JOBS START ============ -->

<!-- <div class="alert alert-warning  visible-xs-block visible-sm-block" style="margin: 15px;">
  <span class="covid-text"><b class="highlight">COVID-19</b>&nbsp; has placed unforeseen demands on hospital and medical facilities around the country and Lifeline is here to help you address the surging need for the right staff in these critical roles. Our Allied Health group can quickly and accurately find the right talent for your lab specialists so that your mission to provide the best health care can continue unimpeded.</span>
</div> -->



<section id="jobs">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <p class="content-display">
          Lifeline is a premier healthcare staffing company with a dual focus. We seek to both support our customers with the highest quality staff within the medical field today and at the same time give the healthcare worker the respect and consideration they have come to deserve. We recognize the contributions these tireless and extremely hardworking individuals give to their profession each day and many if not all of us benefit from their focus and dedication. It is our goal to find the best people for the role they are best suited to fill with the result being that our customers are best served and delighted with our people.
        </p>
      </div>
    </div>
    <!-- <div class="row">

      <div class="col-sm-8">
        <h2>Recent Jobs</h2>

        <div class="jobs ">

         
          <a class="featured applied" *ngFor="let item of localdata .slice().reverse() | slice:0:7; let i=index">
            <div class="row" (click)=" OnClickJob(item);">

              <div class="col-lg-5 col-md-5 col-sm-7 col-xs-12 job-title">
                <h5>{{item.title}}</h5>

              </div>
              <div class="col-lg-4 col-md-4 col-sm-5 col-xs-12 job-location">
                <p><strong>{{item.client_location}}</strong></p>
              </div>
              <div class="col-lg-2 col-md-2 hidden-sm hidden-xs job-type text-center">
                <p class="badge apply">Apply Now</p>
              </div>
            </div>
          </a>

        </div>

        <a (click)="OnClickJobpage()" class="btn btn-primary" id="more-jobs">
          <span>Show More Jobs </span>
        </a>

      </div>
      <div class="col-sm-4" *ngFor="let item of localdata .slice().reverse() | slice:0:1; let i=index">
        <h2>Featured Jobs</h2>
        <a href="#">
          <img src="{{item.display_image1}}" alt="Featured Job" class="img-responsive" />
          <div class="featured-job">

            <div class="title">
              <h5>{{item.title}}</h5>

            </div>
            <div class="data">
              <span class="city"><i class="fa fa-map-marker"></i>{{item.client_location}}</span>
              <span class="type full-time"><i class="fa fa-clock-o"></i>{{item.job_type}}</span>

            </div>
            <p [innerHTML]="item.body | slice:0:94" class="justifyclass description"></p>
          </div>
        </a>
      </div>
    </div> -->
  </div>
</section>

<!-- ============ REGISTER END ============ -->
<section id="about" class="parallax p2white">
  <div class="footer-alignments">
    <div class="row">
      <div class="col-sm-4">
        <h2 class="h2white footer1">	WHAT WE OFFER</h2>
        <p style="text-align:justify">Lifeline provides highly qualified personnel resources to our hospitals and other partners in the ever-changing medical field since 2008. Our expertise and recruiting professionals ensure quality in every aspect of the healthcare staffing process and with over a decade of experience we have been able to streamline the hiring process and removing the stress of unfilled positions. </p>


      </div>
      <div class="col-sm-4">
        <h2 class="h2white footer1"> 	WHAT WE DO</h2>
        <p style="text-align:justify">Lifeline utilizes a best in class recruiting system and are adding to our database of allied health and clinical professionals on a daily basis. This allows us to find the most qualified and best fit candidate in the shortest amount of time. With a nationwide partner network, we can address the needs of any US based hospital or health facility regardless of location.</p>
      </div>
      <div class="col-sm-4" >
        <h2 class="h2white footer1"> WHAT WE PROVIDE</h2>
        <p class="specialistp">Lifeline is able to quickly provide qualified and credentialed professionals in the following areas:</p>
        
        <ul class="specialistlist">
          <li class="specialists">Registered Nurses – across a range of specializations</li>
          <li class="specialists">LPN/LVNs</li>
          <li class="specialists">Allied Health personnel</li>
          <li class="specialists">Health Information Technologists</li>
        </ul>
      </div>
    </div>
  </div>
</section>

