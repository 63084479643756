import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-recruitment',
  templateUrl: '../views/recruitment.component.html',
  
})
export class RecruitmentComponent implements OnInit {

  constructor(private route: ActivatedRoute, private routes: Router) { }

  ngOnInit(): void {    
    window.scrollTo(0,0);
  }
  applyNow() {
    this.routes.navigate(['/quick-apply']);
  }

}
