<!-- ============ SLIDES START ============ -->

<div id="slider" class="sl-slider-wrapper">

  <div class="sl-slider">
    <div class="sl-slide" data-orientation="horizontal" data-slice1-rotation="-25" data-slice2-rotation="-25" data-slice1-scale="2" data-slice2-scale="2">
      <div class="sl-slide-inner">
        <div class="bg-img bg-img-video"></div>
        <div class="tint"></div>      
      </div>
    </div>
  </div>
</div>
<br>
<section id="jobs">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <p class="content-display">
          Lifeline is a premier healthcare staffing company with a dual focus. We seek to both support our customers with the highest quality staff within the medical field today and at the same time give the healthcare worker the respect and consideration they have come to deserve. We recognize the contributions these tireless and extremely hardworking individuals give to their profession each day and many if not all of us benefit from their focus and dedication. It is our goal to find the best people for the role they are best suited to fill with the result being that our customers are best served and delighted with our people.
        </p>
      </div>
    </div>   
  </div>
</section>

<!-- ============ REGISTER END ============ -->
<section id="about" class="parallax p2white">
  <div class="footer-alignments">
    <div class="row">
      <div class="col-sm-4">
        <h2 class="h2white footer1">	WHAT WE OFFER</h2>
        <p style="text-align:justify">Lifeline provides highly qualified personnel resources to our hospitals and other partners in the ever-changing medical field since 2008. Our expertise and recruiting professionals ensure quality in every aspect of the healthcare staffing process and with over a decade of experience we have been able to streamline the hiring process and removing the stress of unfilled positions. </p>
      </div>
      <div class="col-sm-4">
        <h2 class="h2white footer1"> 	WHAT WE DO</h2>
        <p style="text-align:justify">Lifeline utilizes a best in class recruiting system and are adding to our database of allied health and clinical professionals on a daily basis. This allows us to find the most qualified and best fit candidate in the shortest amount of time. With a nationwide partner network, we can address the needs of any US based hospital or health facility regardless of location.</p>
      </div>
      <div class="col-sm-4" >
        <h2 class="h2white footer1"> WHAT WE PROVIDE</h2>
        <p>Lifeline is able to quickly provide qualified and credentialed professionals in the following areas:</p>        
        <ul class="specialistlist" style="margin-top: -7%;">
          <li class="specialists">Registered Nurses - across a range of specializations</li>
          <li class="specialists">LPN/LVNs</li>
          <li class="specialists">Allied Health personnel</li>
          <li class="specialists">Health Information Technologists</li>
        </ul>
      </div>
    </div>
  </div>
</section>

