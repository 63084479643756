<!-- ============ TITLE START ============ -->

<section id="title" style="margin-top:-2%">
  <div class="container-fluid">
    <div class="row ">

      <div class="col-sm-6">
        <img src="assets/images/LeadershipBanner.png" alt="" class="img-responsive" />
       
      </div>
    
    <!-- ============ TITLE END ============ -->
    <!-- ============ STORY START ============ -->
    
      <div class="col-sm-6 content-background ">


        <div>
          <a routerLink="/profile/manoj-devulapalli"><h3 class="leadership-style">Manoj Devulapalli </h3><div class="leadership-postion"> President/CEO</div></a>
        </div>
        <br />

        <!--<div>
    <a routerLink="/profile/dr-rao"><h3 class="leadership-style"> Dr. Sri Rao</h3><div class="leadership-postion"> Senior Medical Director</div></a>
  </div><br />-->

        <div>

          <a routerLink="/profile/rick-jelley"><h3 class="leadership-style">Rick Jelley </h3><div class="leadership-postion">Director of Operations</div></a>
        </div><br />

        <div>
          <a routerLink="/profile/duane-davis"><h3 class="leadership-style">Duane Davis</h3><div class="leadership-postion">Director of Business Development</div></a>
        </div><br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        
      </div>
    </div>
  </div>
</section>


