import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { JobCatagory, JobClass, JobMapping, JobSubCatagory } from '../ViewModels/jobmodel';
import { GpRequest, StaffRequest } from '../Service Model/Staffing';
import { ContactRequest } from '../Service Model/Apply';
import { LifelineResponse } from '../Service Model/Response';
import { StatesResponse } from '../Service Model/StatesResponse';
import { JobsResponse } from '../Service Model/JobsResponse';
import { environment } from '../../environments/environment';//for environment


@Injectable({
  providedIn: 'root'
})
export class JobPostService {
env :boolean=false; //true for production and false for dev 
baseURL:string="./assets/api";
//ApiURL :string="http://gp-api.yathisolutions.com/v1/";
//ApiURL :string="http://localhost:52684/v1/";
ApiURL = environment.ApiURL;
GpRequest: GpRequest=new GpRequest;

  constructor(private http:HttpClient) { 
    this.GpRequest.Key = "Unknown";
    //to fetch IP address
    this.http.get("https://api.ipify.org/?format=json").subscribe((res: any) => {
      this.GpRequest.IpAddress = res.ip;
    });
  }
  JobData():Observable<JobClass[]>{
    const headerDirect = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Headers': 'Content-Type'
      }
    return this.http.get<JobClass[]>(this.baseURL+'/Postjob.json', { headers: headerDirect });
  }

  JobMapping():Observable<JobMapping[]>{
    const headerDirect = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Headers': 'Content-Type'
      }
    return this.http.get<JobMapping[]>(this.baseURL+'/Job_catagories_mapping.json', { headers: headerDirect });
  }

  JobCatagories():Observable<JobCatagory[]>{
    const headerDirect = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Headers': 'Content-Type'
      }
    return this.http.get<JobCatagory[]>(this.baseURL+'/Job_catagories.json', { headers: headerDirect });
  }

  JobSubCatagories():Observable<JobSubCatagory[]>{
    const headerDirect = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Headers': 'Content-Type'
      }
    return this.http.get<JobSubCatagory[]>(this.baseURL+'/job_sub_catagories.json', { headers: headerDirect });
  }

  RequestStaffing(StaffRequest : StaffRequest ):Observable<LifelineResponse>{
    const headerDirect = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Headers': 'Content-Type'
      }
      StaffRequest.GpRequest=this.GpRequest;
      

    return this.http.post<LifelineResponse>(this.ApiURL+'request/staff',  JSON.stringify(StaffRequest), { headers: headerDirect });
  }

  ApplyJob(ContactRequest : ContactRequest ):Observable<LifelineResponse>{
    const headerDirect = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Headers': 'Content-Type'
      }
      ContactRequest.GpRequest=this.GpRequest;    
      
    return this.http.post<LifelineResponse>(this.ApiURL+'contact',  JSON.stringify(ContactRequest), { headers: headerDirect });
  }

  UploadFile(formData : FormData ):Observable<LifelineResponse>{
    const headerDirect = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Headers': 'Content-Type'
      }     
 
    return this.http.post<LifelineResponse>(this.ApiURL+'upload', formData);
  }

  getStates(request : GpRequest ):Observable<StatesResponse>{
    const headerDirect = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Headers': 'Content-Type'
      }     
 
    return this.http.post<StatesResponse>(this.ApiURL+'get-states-list', request);
  }
  getjobs(request : GpRequest ):Observable<JobsResponse>{
    const headerDirect = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Headers': 'Content-Type'
      }     
 
    return this.http.post<JobsResponse>(this.ApiURL+'get-jobs-list', request);
  }

}

