
  export class StaffRequest{    
    Model: Model=new Model;
    GpRequest: GpRequest=new GpRequest;
  }    

   export class Model{    
    Company:string ='';
    Name:string  ='';
    Title:string  ='';
    PositionsRequired:string  ='';
    ContactNumber:string  ='';
    EmailAddress:string  ='';   
    }

    export class GpRequest{
        Key: string="";
        IpAddress:string="";
      }
