import { Component } from '@angular/core';
import { Router } from '@angular/router';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  title = 'HealthCare';
  current: string='';
  href: string='';
  
  constructor(private router: Router) {       
    router.events.subscribe((val) => {
     
      this.current=this.router.url.toString();
      if(this.current!==this.href){       
        this.CollapseMenu(this.current);              
      }
      this.href = this.router.url.toString();
  });
 }
 CollapseMenu(url:string){
   console.log(url); 
  //  let element: HTMLElement = document.getElementsByClassName( 'navbar-toggler' )[ 0 ] as HTMLElement;
  //  element.click();
 }
}
