import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-joint-commission',
  templateUrl: '../views/joint-commission.component.html'
 
})
export class JointCommissionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    window.scrollTo(0,0);
  }

}
