import { GpRequest } from "./Staffing";

export class ContactRequest{    
    Model: ApplyRequest=new ApplyRequest;
    GpRequest: GpRequest=new GpRequest;
  }    

   export class ApplyRequest{   
    JobTitle:string='';  
    FirstName:string ='';
    LastName:string  ='';
    ContactNumber:string  ='';
    EmailAddress:string  ='';  
    Attachemnt :any;  
    Discipline:string='';
    }