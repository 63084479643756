<section id="title" style="margin-top:-2%">
  <div class="container-fluid">
    <div class="row ">

      <div class="leader-section-container">
        <div class="leader-image-container">
            <img src="/assets/images/client-images/Manoj_Devulapalli.jpg" alt="Manoj Devulapalli">
        </div>
        <div class="content-container">
            <h2>Manoj Devulapalli</h2>
            <div class="designation">President & CEO</div>
            
            <p>A visionary leader with over 25 years in the staffing industry, Manoj founded Globalpundits Inc. in 2000 to serve Engineering and IT sectors and later established Lifeline Healthcare Professionals in 2008, delivering top-tier nursing, allied, and locum staffing solutions. His workforce strategy and client-centric solutions expertise have made him a trusted partner in building high-performing teams across multiple industries.</p>
            <div class="social-icons">
                <a href="https://www.linkedin.com/in/manoj-devulapalli-5963001/" target="_blank" rel="noopener noreferrer"><img src="assets/images/icons/linkedin-icon.png"alt="LinkedIn"></a>
            </div>
        </div>
        </div>
        <div class="leader-section-container">
        <div class="leader-image-container">
            <img src="/assets/images/client-images/Peter_Giglione.jpg" alt="Peter Giglione">
        </div>
        <div class="content-container"> 
            <h2>Peter Giglione</h2>
            <div class="designation">Vice President of Client Services</div>
            <p>Peter Giglione is a seasoned healthcare staffing professional with over 20 years of experience in talent acquisition and client management. His expertise spans all facets of staffing and recruiting, including client acquisition, workforce solutions, and operational management. Peter has successfully built and maintained strong relationships with healthcare providers and organizations of all sizes, from independent facilities and large health systems to insurers, physician groups, PBMs, and pharmaceutical companies. His deep industry knowledge and commitment to excellence make him a trusted partner in the ever-evolving healthcare landscape.</p>
            <div class="social-icons">
                <a href="https://www.linkedin.com/in/pgiglione/" target="_blank" rel="noopener noreferrer"><img src="assets/images/icons/linkedin-icon.png"alt="LinkedIn"></a>
            </div>
        </div>
    </div>
    <div class="leader-section-container">
      <div class="leader-image-container">
          <img src="/assets/images/client-images/Leah_Giordano.jpg" alt="Leah Giordano">
      </div>
      <div class="content-container">
          <h2>Leah Giordano</h2>
          <div class="designation">Director of Operations and Business Development</div>
          
          <p>A seasoned Credentialing & Compliance Leader, driving excellence in healthcare staffing with expertise in The Joint Commission, OSHA/HIPAA, FCRA, and compliance regulations. Passionate about process optimization, vendor management, and cross-department collaboration, she plays a key role in business development, leadership mentorship, and technology-driven efficiency, ensuring top-tier workforce solutions.</p>
          <div class="social-icons">
              <a href="https://www.linkedin.com/in/leah-giordano-72b9197b/" target="_blank" rel="noopener noreferrer"><img src="assets/images/icons/linkedin-icon.png"alt="LinkedIn"></a>
          </div>
      </div>
  </div>
  <div class="leader-section-container">
      <div class="leader-image-container">
          <img src="/assets/images/client-images/Binita_Wood.jpg" alt="Binita Wood">
      </div>
      <div class="content-container">
          <h2>Binita Wood</h2>
          <div class="designation">Associate Director of Operations and Compliance</div>
          
          <p>Binita Kumari Wood has 7 years of experience in the recruitment industry, with a total of 11 years of professional experience. She has had the opportunity to work with a leading recruitment firm. Recently, she has brought extensive knowledge of compliance and operations into the rapidly growing healthcare sector. She is dedicated to implementing innovative approaches that help clients connect with the right talent more efficiently.</p>
          <div class="social-icons">
              <a href="https://www.linkedin.com/in/bini-wood-b8969315b/" target="_blank" rel="noopener noreferrer"><img src="assets/images/icons/linkedin-icon.png"alt="LinkedIn"></a>
          </div>
      </div>
  </div>
  <div class="leader-section-container">
      <div class="leader-image-container">
          <img src="/assets/images/client-images/Ravi_Thota.jpg" alt="Ravi Thota">
      </div>
      <div class="content-container">
          <h2>Ravi Thota</h2>
          <div class="designation">Manager of Accounts & Offshore Operations</div>
          
          <p>Ravi is a dynamic Finance & Operations leader with 6+ years in US staffing, driving profitability, efficiency, and seamless operations. A strategic problem-solver and team builder, he excels in process optimization, talent acquisition, and growth-driven strategies, ensuring Lifeline Healthcare’s success in a competitive market.</p>
          <div class="social-icons">
              <a href="https://www.linkedin.com/in/ravi-thota-b7a720129/" target="_blank" rel="noopener noreferrer"><img src="assets/images/icons/linkedin-icon.png"alt="LinkedIn"></a>
          </div>
      </div>
  </div>
    
        
     
  


