import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: '../Views/footer.component.html'
})
export class FooterComponent implements OnInit {

  _year:number=0;
  constructor() { }

  ngOnInit(): void {
    this._year = new Date().getFullYear();

  }

}
