<!-- ============ TITLE START ============ -->

<section id="title">
  <div class="container">
    <div class="row">
      <div class="col-sm-12 text-center">
        <h1>OUR MISSION</h1>
      </div>
    </div>
  </div>
</section>

<!-- ============ TITLE END ============ -->
<!-- ============ STORY START ============ -->

<section id="story">
  <div class="container">
    <div class="row">
      <div class="col-sm-6">
        <h4>Mission Statement</h4>
        <br />
        <p class="content-display">
          Our mission at Lifeline Healthcare Professionals is to offer our clients and staff the best-in-class service in all aspects of healthcare staffing. This commitment extends to our entire range of customers in providing the best possible clinical and non-clinical professionals ensuring they are fully vetted and credentialed and ready and able to provide unexcelled care for all patients. As a national staffing company, we are fully committed to our workers to never stop searching for the ideal work situation wherever that may be around the country. Our goal is simple: to be the best in the industry by continually exceeding all expectations in our relentless pursuit of excellence
        </p>
      </div>
      <div class="col-sm-6">
        <img src="assets/images/Picture3.jpg" alt="" class="img-responsive">
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="col-sm-6">
          <img src="assets/images/Picture2.jpg" alt="" class="img-responsive">
        </div>
        <div class="col-sm-6" style="margin-top: 6%;">
          <h4>Focus</h4>
          <br />
          <p class="content-display">
            Our focus is on the two primary components of our business—our employees and the clients they support. Whether it be a specialized Children’s hospital, a rural Community hospital or a large multi hospital system, we endeavor to find the best suited nurses that can assist them at every level of care needed. By assiduously and constantly supporting our healthcare professionals in the field, they in turn can give their best efforts to our customers. Our recruiters and account managers understand that in this increasingly remote work world, precise and timely communication is the key to success in both employee and client satisfaction.
          </p>
        </div>

      </div>
    </div>



  </div>
</section>


