<section id="title" *ngIf="Img==1">
  <div class="container-fluid">
    <div class="row">     
      <div class="col-lg-12 text-center">
        <img src="assets/images/florida-pool-license.jpg"  alt="" class="img-responsive">
      </div>
    </div>
  </div>
</section>

<section id="title" *ngIf="Img==2">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12 text-center">
        <img src="assets/images/florida-pool-license-content.jpg" alt="" class="img-responsive">
      </div>
    </div>
  </div>
</section>


<section id="title" *ngIf="Img==3">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12 text-center">
        <img src="assets/images/illinoi_nurse_agency_license.jpg" alt="" class="img-responsive">
      </div>
    </div>
  </div>
</section>

<section id="title" *ngIf="Img==4">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12 text-center">
        <img src="assets/images/illinoi_nurse_agency_license_content.jpg" alt="" class="img-responsive">
      </div>
    </div>
  </div>
</section>
