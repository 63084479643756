<div id="form-loader" *ngIf="isLoad">
  <i class="fa fa-cog fa-4x fa-spin"></i>
</div>
<!-- ============ TITLE START ============ -->

<div id="backgroundImageRS">
  <div>
    <section id="title">
      <div class="container">
        <div class="row">
    
        </div>
      </div>
    </section>
    
    <!-- ============ TITLE END ============ -->
    <!-- ============ CONTENT START ============ -->
    
    
<section id="jobs">
  <div class="container">
    
    <div class="row ">
      
      <div class="col-sm-2">       
      </div>
      <div class="col-sm-8" style="background-color: white;padding: 3%; border: 4px solid gray;">
        <h1>Request Staffing</h1>
        <br>
        <div class="alert alert-{{SubmitClass}}" role="alert">
          {{SubmitMessage}}
        </div>
        <form [formGroup]="form" (ngSubmit)="submit()">
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <label for="fname">Name &nbsp;<span class="error-text">*</span></label>
                <input formControlName="name" id="name" type="text" class="form-control">
                <div *ngIf="f.name.touched && f.name.invalid" class="error-text">
                  <div *ngIf="f.name.errors?.required">Name is required.</div>
                  <div *ngIf="!f.name.errors?.minLength && !f.name.errors?.required">Name should contain atleast 3 character.</div>
                </div>
              </div>

            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <label for="lname">Company &nbsp;<span class="error-text">*</span></label>
                <input formControlName="company" id="company" type="text" class="form-control">
                <div *ngIf="f.company.touched && f.company.invalid" class="error-text">
                  <div *ngIf="f.company.errors?.required">Company is required.</div>
                  <div *ngIf="!f.company.errors?.minLength && !f.company.errors?.required">Company should contain atleast 3 character.</div>
                </div>
              </div>
            </div>

          </div>

          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <label for="fname">Job Title &nbsp;<span class="error-text">*</span></label>
                <input formControlName="job_title" id="job_title" type="text" class="form-control">
                <div *ngIf="f.job_title.touched && f.job_title.invalid" class="error-text">
                  <div *ngIf="f.job_title.errors?.required">Job title is required.</div>
                  <div *ngIf="!f.job_title.errors?.minLength && !f.job_title.errors?.required">Job title should contain atleast 3 character.</div>
                </div>
              </div>
            </div>


            <div class="col-sm-6">
              <div class="form-group">
                <label for="name">Email &nbsp;<span class="error-text">*</span></label>
                <input formControlName="email" id="email" type="text" class="form-control">
                <div *ngIf="f.email.touched && f.email.invalid" class="error-text">
                  <div *ngIf="f.email.errors?.required">Email is required.</div>
                  <!-- <div *ngIf="f.email.errors?.email && !f.email.errors?.required ">Email is Invalid</div> -->
                  <div *ngIf="f.email.errors?.pattern">Email is Invalid</div>
                </div>
              </div>
            </div>

          </div>

          <div class="row">
            <!-- <div class="col-sm-6">
              <div class="form-group">
                <label for="lname">Mobile  &nbsp;<span style="font-size: 10px;">(optional)</span></label>
                <input formControlName="phone" id="lname" type="text" class="form-control">                
              </div>
            </div> -->
            <div class="col-sm-6">              
            </div>
          </div>

          <div class="row">            
              <div class="col-sm-6">
                <br />
                <button class="btn btn-primary" type="submit" [disabled]="!form.valid" >Submit</button>
              </div>
            </div>


</form>
      </div>
    
      <div class="col-sm-2">       
      </div>
     
    </div>
  </div>
</section>
  </div>
</div>


