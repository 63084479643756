export class JobClass{
    id:number=0;
    title:string ='';
    body:string  ='';
    client:string  ='';
    job_duration?:string='';
    client_location:string  ='';
    job_type:string  ='';
    display_image:string  ='';
    display_image1:string  ='';
    display_image2:string  ='';
    required_skills:string  ='';
    preferred_qualification:string  ='';
    job_catagory:number=0;
    job_sub_catagory:number=0;
    job_city:number=0;    
    }

    export class JobMapping{
        id:number=0;
        catagory:number =0;
        sub_catagory:number =0;       
    }
    
    export class JobCatagory{
        id:number= 0;
        catagory:string='';             
    }
    export class JobSubCatagory{
        id:number=0;
        sub_catagories:string ='';             
    }

    export class JobCity{
        id:number=0;
        city:string ='';             
    }