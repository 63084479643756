import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { JobPostService } from 'src/app/Services/job-post.service';
import { JobClass} from 'src/app/ViewModels/jobmodel';
import jobapidata from '../../../assets/api/Postjob.json';


@Component({
  selector: 'app-home',
  templateUrl: '../views/home.component.html'
})
export class HomeComponent implements OnInit {
 localdata:JobClass[]=jobapidata;

  constructor(private http:HttpClient,private job:JobPostService,
    private route:Router) {
   }
  
  ngOnInit(): void {
    window.scrollTo(0,0);
    this.localdata.sort( x => x.id  );
     }

   OnClickJob(data:JobClass){    
    let replaceval=/\ /gi;
    let newtitle=data.title.replace(replaceval,"-");
    this.route.navigate(['/job-details',data.id,newtitle.toLowerCase()]);
   }
   OnClickpage(){      
    this.route.navigate(['/contactus']);
   }
   OnClickJobpage(){      
    this.route.navigate(['/jobs']);
   }
}
