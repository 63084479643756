import { Component } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';


@Component({
  selector: 'app-header',
  templateUrl: '../Views/header.component.html'
})
export class HeaderComponent {

    constructor(private router: Router) {
        this.router.events.subscribe((event) => {
          if (event instanceof NavigationEnd) {
            this.closeMenu();
          }
        });
      }
    
      closeMenu() {
        const navbar = document.querySelector('.navbar-collapse');
        if (navbar) {
          navbar.classList.remove('in');
          navbar.classList.remove('show');
        }
      }
    }