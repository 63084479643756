import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { LifelineResponse } from 'src/app/Service Model/Response';
import { StaffRequest } from 'src/app/Service Model/Staffing';
import { JobPostService } from 'src/app/Services/job-post.service';


@Component({
  templateUrl: '../views/request-staffing.component.html',
})
export class RequestStaffingComponent implements OnInit {
  isLoad: boolean = false;
  SubmitMessage: string = '';
  SubmitClass: string = '';
  StaffRequest: StaffRequest = new StaffRequest();
  Result: LifelineResponse = new LifelineResponse();
  constructor(private _jobPostService: JobPostService) { }

  ngOnInit(): void {
    window.scrollTo(0, 0);
  }

  form = new FormGroup({
    name: new FormControl('', [Validators.required, Validators.minLength(3)]),
    company: new FormControl('', [Validators.required, Validators.minLength(3)]),
    job_title: new FormControl('', [Validators.required, Validators.minLength(3)]),
    email: new FormControl('', [Validators.required, Validators.email, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
    phone: new FormControl(''),

  });

  get f() {
    return this.form.controls;
  }


  submit() {
    this.isLoad = true;
    this.StaffRequest.Model.Company = this.form.value['company'];
    this.StaffRequest.Model.Name = this.form.value['name'];
    this.StaffRequest.Model.Title = this.form.value['job_title'];
    this.StaffRequest.Model.PositionsRequired = this.form.value['positions'];
    this.StaffRequest.Model.EmailAddress = this.form.value['email'];
    this.StaffRequest.Model.ContactNumber = this.form.value['phone'];

    this._jobPostService.RequestStaffing(this.StaffRequest).subscribe((x: LifelineResponse) => {
      this.Result = x;

      console.log(this.Result);
      if (this.Result.Status == 1) {
        this.SubmitMessage = "Request Sent Successfully !!!";
        this.SubmitClass = "success";
        this.isLoad = false;
        this.form.reset();
      }
      else {
        this.SubmitMessage = "Sorry !!! Something Went Wrong";
        this.SubmitClass = "danger";
        this.isLoad = false;
      }
    }
    )

  }

}
