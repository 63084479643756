<!-- ============ TITLE START ============ -->

<div id="form-loader" *ngIf="isLoad">
  <i class="fa fa-cog fa-4x fa-spin"></i>
</div>

<div id="backgroundImageQA">
  <div>
    <section id="title">
      <div class="container">
        <div class="row">
          
        </div>
      </div>
    </section>
    
    <!-- ============ TITLE END ============ -->
    <!-- ============ CONTENT START ============ -->
    
    <section id="jobs">
      <div class="container">       
        <div class="row ">
         
          <div class="col-sm-2">
            
          </div>

          <div class="col-sm-8" style="background-color: white;padding: 3%; border: 4px solid gray;">
            <h1>{{jobsModel.JobTitle}}</h1>
        <h4>
          <span><i class="fa fa-map-marker"></i>{{jobsModel.City}}, {{jobsModel.StateText}}</span>
          <span><i class="fa fa-clock-o"></i>{{jobsModel.JobType}}</span> 
           
        <p class="badge freelance">{{jobsModel.JobDuration}}</p>         
        </h4>  
            <br>
            
            <!-- <div class="alert alert-info" role="alert" *ngIf="isLoad">
            <i class="fa fa-cog fa-2x fa-spin" ></i> &nbsp;&nbsp; Loading...
          </div> -->
            <div class="alert alert-{{SubmitClass}}" role="alert">
              {{SubmitMessage}}
            </div>
            <form [formGroup]="form" (ngSubmit)="submit()">
              <div class="row">
                <div class="col-sm-6">
                  <div class="form-group">
                    <label for="fname">First Name &nbsp;<span class="error-text">*</span></label>
                    <input formControlName="fname" id="fname" type="text" class="form-control">
                    <div *ngIf="f.fname.touched && f.fname.invalid" class="error-text">
                      <div *ngIf="f.fname.errors?.required">First Name is required.</div>
                      <div *ngIf="!f.fname.errors?.minLength && !f.fname.errors?.required">First Name should be 3 character.</div>
                    </div>
                  </div>
    
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label for="lname">Last Name &nbsp;<span class="error-text">*</span></label>
                    <input formControlName="lname" id="lname" type="text" class="form-control">
                    <div *ngIf="f.lname.touched && f.lname.invalid" class="error-text">
                      <div *ngIf="f.lname.errors?.required">Last Name is required.</div>
                    </div>
                  </div>
                </div>
    
              </div>
    
              <div class="row">
                <div class="col-sm-6">
                  <div class="form-group">
                    <label for="name">Email &nbsp;<span class="error-text">*</span></label>
                    <input formControlName="email" id="email" type="text" class="form-control">
                    <div *ngIf="f.email.touched && f.email.invalid" class="error-text">
                      <div *ngIf="f.email.errors?.required">Email is required.</div>
                      <!-- <div *ngIf="f.email.errors?.email && !f.email.errors?.required ">Email is Invalid</div> -->
                      <div *ngIf="f.email.errors?.pattern">Email is Invalid</div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label for="lname">Discipline &nbsp;<span class="error-text">*</span></label>
                    <select  placeholder="Discipline" formControlName="discipline" class="form-control">
                      <option value="null" disabled>-- Select Discipline --</option>
                      <option value="RN">RN</option>                  
                      <option value="LPN">LPN</option>
                      <option value="ALLIED HEALTH">ALLIED HEALTH</option>
                    </select>
                    <div *ngIf="f.discipline.touched && f.discipline.invalid" class="error-text">
                      <div *ngIf="f.discipline.errors?.required">Discipline is required.</div>
                    </div>               
                  </div>
                </div>
              
              </div>
    
              <div class="row">
                <!-- <div class="col-sm-6">
                  <div class="form-group">
                    <label for="lname">Mobile &nbsp;<span style="font-size: 10px;">(optional)</span></label>
                    <input formControlName="phone" type="text" class="form-control">                
                  </div>
                </div> -->
                <div class="col-sm-6">
                  <div class="form-group">
                    <label for="resume">Resume  &nbsp;<span style="font-size: 10px;">(optional)</span></label>
                   
                    <input type="file" #file placeholder="Choose file" (change)="uploadFile(file.files)" class="form-control" accept=".doc,.docx,.txt,.pdf,.rtf,.odt">
                </div>
                </div>
    
    
              </div>
              <div class="row">
                <div class="col-sm-6">
                  <br>             
                    
                  
                  <button class="btn btn-primary" type="submit" [disabled]="!form.valid">
                   
                    Submit</button>
                </div>
    
              </div>
    
    
            </form>
          </div>
          <div class="col-sm-2"></div>    
        </div>
      </div>
    </section>
    
  </div>
</div>

<!-- ============ CONTENT END ============ -->
