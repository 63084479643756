import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { JobPostService } from 'src/app/Services/job-post.service';
import { JobClass} from 'src/app/ViewModels/jobmodel';
import jobapidata from '../../../assets/api/Postjob.json';

@Component({
  selector: 'app-job-details',
  templateUrl: '../views/job-details.component.html'
  // styleUrls: ['./job-details.component.scss']
})
export class JobDetailsComponent implements OnInit {
 SentId:number=0;
 localdata:JobClass[]=jobapidata;
 ReceivedData: JobClass=new JobClass();
  constructor(private route: ActivatedRoute,private job:JobPostService,private routes:Router) { }

  ngOnInit(): void {
    window.scrollTo(0,0);
    this.route.paramMap.subscribe((params) => {
      this.SentId=Number(params.get("id"));
      let dataval=this.localdata.find(p=>p.id==this.SentId);
     // alert(JSON.stringify(this.localdata));
      if(dataval !=null){
        this.ReceivedData=dataval;
      } 
      window.scrollTo(0,0);    

     
    })
     
  }

  onClick(){ 
    //console.log("Fetch");      
    let titleval=this.localdata.find(p=>p.id==this.SentId);
    if (titleval != null)
    {  
    let replaceval=/\ /gi;
    let newtitle=titleval.title.replace(replaceval,"-");
    this.routes.navigate(['/apply',this.SentId, newtitle.toLowerCase()]);    

   }
  }
}
