import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-contactus',
  templateUrl: '../views/contactus.component.html',
  // styleUrls: ['./contactus.component.scss']
})
export class ContactusComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    window.scrollTo(0,0);
  }

}
