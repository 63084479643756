import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-profile',
  templateUrl: '../views/profile.component.html',

})
export class ProfileComponent implements OnInit {
  displaydata:string= '';
  profileContent:string= '';
  profiletitle:string= '';
  constructor(private route: ActivatedRoute, private routes: Router) { }

  ngOnInit(): void {
    window.scrollTo(0,0);
    this.route.paramMap.subscribe((params) => {     
      let p =  params.get("id");    
      
      if (p!= '' && p!= null) {
        this.displaydata = p.toString();
        if(this.displaydata=='manoj-devulapalli'){
          this.profiletitle="Manoj Devulapalli"
          this.profileContent="Manoj Devulapalli has been in the staffing industry for over 20 years. In 2000 he founded GlobalPundits Technology Consultancy, which is a staff augmentation company focused on supporting clients primarily in Engineering and Information Technology. In late 2008 he founded Lifeline Healthcare Professionals, providing nurses and Allied health staff to hospitals primarily in the South Carolina region. He brings a strong and practical knowledge of staffing across many disciplines and is very adept at building strong supporting companies to more than adequately meet client requirements."
        }
        //else if(this.displaydata=='dr-rao'){
        //  this.profiletitle=" Dr. Rao"
        //  this.profileContent=" Dr. Rao has nearly 20 years of experience as a physician, teacher and in development of healthcare systems around the world. Having worked as a Locum Tenens physician for over 10 years, Dr Rao has a unique insight to the high-level staffing shortages hospitals are currently facing around the country. As a still-practicing cardiologist, he can quickly review and decipher your institution's personnel needs at all levels and offer timely solutions. Contact Dr. Rao to see how Lifeline Healthcare Professionals can help you.<span class='target badge'> dr.rao@lifelinehcp.com</span>"
        //}
        else if(this.displaydata=='rick-jelley'){
          this.profiletitle="Rick Jelley"
          this.profileContent="Rick Jelley has been in recruiting and staffing for over 25 years. He has had the privilege of working with some of the largest professional services firms and IT firms in the world including Accenture, Booz Allen Hamilton, Lockheed Martin, and Verizon. Recently he has brought his vast knowledge of recruiting and operations into the ever-expanding healthcare arena. He is adept at seeing the big picture and then understanding the necessary actions to accomplish the overall objectives. He is at home in the ever-changing world of recruiters, candidates and clients and balancing their needs. He can be reached at <span class='target badge'>rick@lifelinhcp.com.</span>"
        }
       else if(this.displaydata=='duane-davis'){
          this.profiletitle="Duane Davis"
          this.profileContent="Duane Davis has been in recruiting and business development for 20 years. He began his career at Allegis Group which is in the top 5 staffing companies in the world. Adept at both recruiting and business development, Duane has broken over 50 new accounts during his career and opened 5 new offices in Ohio. He is transitioning his strong business acumen into the healthcare staffing world and is already experiencing strong success. Please reach out directly to Duane for any staffing requirements for your organization. He can be reached at <span class='target badge'> duane@lifelinehcp.com</span>"
        }
        else {
          this.routes.navigate(['/']);
        }
      }
      else {
        this.routes.navigate(['/']);
      }

    })
  }
   
}
