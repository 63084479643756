<!-- ============ TITLE START ============ -->

<section id="title">
    <div class="container">
      <div class="row">
        <div class="col-sm-12 text-center">
          <h1> State Staffing Registrations - Illinois</h1>
        </div>
      </div>
    </div>
  </section>
  
  <!-- ============ TITLE END ============ -->
  <!-- ============ STORY START ============ -->
  


  <section id="story protfolio ">
    <!--[ngClass]="{'show':protfolio}"-->
    <div class="container">
      <div class="row">
        <div class="col-sm-6 project">
          <img src="assets/images/illinoi_nurse_agency_license.jpg" alt="" class="img-responsive gallery-item">
          <div class="grid-overlay" type="button" id="OtherResolutionModal" [hidden]="true" data-toggle="modal" data-target="#kt_modal_6_1">
            <button class="viewbutton" (click)="onClickView(3)">view</button>
          </div>
           </div>
  
  
        <div class="col-sm-6 project">
          <img src="assets/images/illinoi_nurse_agency_license_content.jpg" alt="" class="img-responsive gallery-item">
          <div class="grid-over" type="button" id="OtherResolutionModal" [hidden]="true" data-toggle="modal" data-target="#kt_modal_6_1">
            <button class="viewbutton" (click)="onClickView(4)">view</button>
  
          </div>
        </div>
      </div>
    </div>
  </section>
  
  
  
  <div class="modal fade" id="kt_modal_6_1" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          </button>
        </div>
        <app-img [Img]="Img"></app-img>
       </div>
    </div>
  </div>
  