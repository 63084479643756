<!-- ============ TITLE START ============ -->
<div id="backgroundImageJC">

    <div class="main">

        <section id="title">
            <div class="container">
                <div class="row">
                    <div class="col-sm-12 text-center">
                        <h1>Joint Commission Certification</h1>
                    </div>
                </div>
            </div>
        </section>

        <!-- ============ TITLE END ============ -->

        <!-- ============ STORY START ============ -->

        <section id="story" class="special-container">
            <div class="container ">
                <div class="row">
                    <div class="col-sm-12">
                        <br><br>
                        <h2>Healthcare Staffing Service Certification</h2>
                    </div>
                    <div class="col-sm-6">
                        <p class="content-display">
                            Lifeline Healthcare Professionals has earned The Joint Commission’s Gold Seal of Approval®
                            for Health Care Staffing Services Certification by demonstrating continuous compliance with
                            its performance standards. The Gold Seal is a symbol of quality that reflects a health care
                            organization’s commitment to providing safe and quality patient care.
                        </p>
                        <p class="content-display">
                            “Health Care Staffing Services Certification recognizes health care staffing firms committed
                            to fostering continuous quality improvement in patient safety and quality of care,” says
                            Mark Pelletier, RN, MS, chief operating officer, Accreditation and Certification Operations,
                            and chief nursing executive, The Joint Commission. “We commend Lifeline Healthcare
                            Professionals for using certification to strengthen its program structure and management
                            framework, as well as to enhance its staff recruitment and development processes.”
                        </p>
                    </div>
                    <div class="col-sm-6">
                        <img src="/assets/images/jc.png" width="60%" class="center" />
                    </div>
                </div>
            </div>
        </section>
        <section id="team" class="color1">
            <div class="container">
                <div class="row">
                    <a href="assets/images/policy-statement.pdf" target="_blank">
                        <div class="col-sm-3">
                            <div class="text-center">
                                <h4>Joint Commission Policy Statement</h4>
                                <h4 style="margin: 0px;"><br></h4>
                            </div>
                        </div>
                    </a>

                    <a href="assets/images/employee-handbook.pdf" target="_blank">
                        <div class="col-sm-3 ">
                            <div class="text-center">
                                <h4>Lifeline Healthcare Professionals Employee Handbook</h4>
                            </div>
                        </div>
                    </a>
                    <a href="assets/images/employee-handbook-acknowledgement-form.pdf" target="_blank">
                        <div class="col-sm-3">
                            <div class="text-center">
                                <h4>Employee Handbook Acknowledgement Form</h4>
                            </div>
                        </div>
                    </a>
                    <a href="assets/images/education-links.pdf" target="_blank">
                        <div class="col-sm-3">
                            <div class="text-center">
                                <h4>Continuing Education Links</h4>
                                <h4 style="margin: 0px;"><br></h4>
                            </div>
                        </div>
                    </a>

                </div>
            </div>
        </section>

    </div>

</div>