<style>
  .header a {
    color: white;
  }

    .header a:hover {
      color: black;
    }
</style>
<header>
  <div class="container">

    <nav class="navbar-default">
      <div class="container-fluid">
        <div class="navbar-header">
          <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#navbar" aria-expanded="false" aria-controls="navbar">
            <span class="sr-only">Toggle navigation</span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
          </button>
          <div id="logo"><a href="/"><img src="/assets/images/Lifeline_Healthcare_Newlogo.png" alt="Lifeline-Healthcare-logo" /></a></div>
        </div>
        <div id="navbar" class="navbar-collapse collapse">
          <ul class="nav navbar-nav navbar-right">
            <li class="header"><a href="/" style="color: white;">Home</a></li>
            <li class="dropdown header">
              <a href="#" class="dropdown-toggle " data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false" style="color: white;">Compliance &nbsp;<span class="caret"></span></a>
              <ul class="dropdown-menu" style="background-color: #004980;  width: 202px;">
                <li class="header">
                  <a routerLink="/compliance/standards">Joint Commission</a>
                </li>
                <li class="dropdown-item dropdown-toggle header dropdown-submenu">
                  <a> State Staffing Registrations &nbsp;<span class="caret-right"></span></a>
                  <ul class="dropdown-menu submenu" style="background-color: #004980;">
                    <li class="header subitem-sub"><a routerLink="/compliance/state-staffing-registrations/florida">Florida</a></li>
                    <li class="header subitem-sub"><a routerLink="/compliance/state-staffing-registrations/illinois">Illinois</a></li>
                  </ul>
                </li>
              </ul>
            </li>
            <li class="header"> <a routerLink="/apply-now/quick-apply" style="color: white;">Quick Apply</a></li>          
            <li class="header"><a routerLink="/request-staffing" style="color: white;">Request Staffing</a></li>
            <li class="dropdown header">
              <a href="#" class="dropdown-toggle " data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false" style="color: white;">About Us &nbsp;<span class="caret"></span></a>
              <ul class="dropdown-menu" style="background-color: #004980;">
                <li class="header"><a routerLink="/about-us/our-mission">Our Mission</a></li>
                <li class="header"><a routerLink="/about-us/leadership">Leadership</a></li>
              </ul>
            </li>
            <li class="header"><a routerLink="/contactus" style="color: white;">Contact</a></li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</header>

