import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-illinois',
  templateUrl: '../views/illinois.component.html',

})
export class IllinoisComponent implements OnInit {

  Img:number=0;
  constructor() { }
  ngOnInit(): void {
    window.scrollTo(0,0);
  }
  onClickView(value: number) {
   this.Img=value;
  }

}
