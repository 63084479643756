import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sidebar',
  templateUrl: '../Views/sidebar.component.html',

})
export class SidebarComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
