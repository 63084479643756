<div id="backgroundImageProfile">
  <div>
    <section id="title">
      <div class="container">
        <div class="row">
        </div>
      </div>
    </section>
    <section>
      <div>
        <div class="container">
          <div class="jumbotron">
            <div class="row">

              <div class="col-sm-12">
                <h2>
                  {{profiletitle}}
                </h2>
                <br />
                <p class="content-display" [innerHTML]="profileContent">
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>
