import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-img',
  templateUrl: '../views/img.component.html',
 
})
export class ImgComponent implements OnInit { 
  @Input()  Img: number=0;

  constructor() { }

  ngOnInit(): void {
    window.scrollTo(0,0);
  }
  
}
