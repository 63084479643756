
<div id="backgroundImageProfile">
  <div>
    <section id="title">
      <div class="container">
        <div class="row">
        </div>
      </div>
    </section>
    <!-- ============ TITLE START ============ -->
    <section id="title">
      <div class="container">
        <div class="row">
          <div class="col-sm-12 text-center">
            <h1>
              OUR TEAM = YOUR TEAM
            </h1>
            <p class="recruitmentstyle">
              At Lifeline we know that having a straightforward, honest, and knowledgeable recruiter is crucial to the hiring process. You can expect dedication, expertise, undivided attention, and timely communication from each of our recruiters as they guide you through your healthcare employment process.
            </p>
          </div>
        </div>
      </div>
    </section>

    <!-- ============ TITLE END ============ -->
    <!-- ============ STORY START ============ -->

    <section id="story">
      <div class="container">
        <div class="row  special-container">
          <br />
          <div class="col-sm-12" style="text-align: center; padding: 10px 78px;">

            <div class="recruitmentstyle"><b>Bobbi Madrigal</b></div>
            <div class="recruitmentstyle">Senior Recruiter </div>
            <p class="recruitmentstyle" style="text-align:justify">
              Bobbi brings 15 years of national recruiting experience and strong dedication to excellence in recruiting to the team.
              Using a consultative approach, she attracts top clinicians for travel .
              She prides herself on her in-depth interview process, which enables her to provide the appropriate candidate to fill the clients’ requirements. 
            </p>
            <div>
              <p style=" text-align: center">
                <i class="fa fa-linkedin-square icon-social" aria-hidden="true"></i>
                <a href="http://linkedin.com/in/bobbi-madrigal" target="_blank" style="text-align: center"> http://linkedin.com/in/bobbi-madrigal</a><br />
                <i class="fa fa-envelope icon-social" aria-hidden="true"></i>
                <a href="mailto:bobbi@lifelinehcp.com" style="text-align: center"> bobbi&#64;lifelinehcp.com</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>


