<!-- ============ TITLE START ============ -->

<section id="title">
  <div class="container">
    <div class="row">
      <div class="col-sm-12 text-center">
        <h1>{{ReceivedData.title}}</h1>
        <h4>
          <span><i class="fa fa-map-marker"></i>{{ReceivedData.client_location}}</span>
          <span><i class="fa fa-clock-o"></i>{{ReceivedData.job_type}}</span>
        </h4>
      </div>
    </div>
  </div>
</section>

<!-- ============ TITLE END ============ -->
<!-- ============ CONTENT START ============ -->

<section id="jobs">
  <div class="container">
    <div class="row ">
      <div class="col-sm-8">
        <article>
          <h2>Job Details</h2>
          <h3>General job duties</h3>
          <h5> Types of procedures required</h5>
          <br />
          <p [innerHTML]="ReceivedData.body" class="justifyclass"></p>
          <h3>Required Skills</h3>

          <p [innerHTML]="ReceivedData.required_skills" class="justifyclass"></p>
          <h3 *ngIf="ReceivedData.preferred_qualification!=''">Preferred qualifications</h3>
          <p [innerHTML]="ReceivedData. preferred_qualification" class="justifyclass"></p>
          <input type="button" value="APPLY TO JOB " class="btn btn-primary" (click)="onClick()">
        </article>
      </div>
       <!-- --- sidebar here--- -->
       <div class="col-sm-4" id="sidebar">
        <br>
        <app-sidebar></app-sidebar>
      </div>
      <!-- ---------sidebar end------- -->
    </div>
  </div>
</section>

<!-- ============ CONTENT END ============ -->
