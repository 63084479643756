

<!-- ============ TITLE START ============ -->
<div id="backgroundImageJC">

    <div class="main">

        <section id="title">
            <div class="container">
                <div class="row">
                    <div class="col-sm-12 text-center">
                        <h1>PRIVACY POLICY</h1>
                        <br><br>
                        <!-- <h4>Lifeline Healthcare Professionals is proud to have earned the Joint Commission’s Gold Seal of Approval.</h4>                -->
                        <br><br>
                    </div>
                </div>
            </div>
        </section>
        
        <!-- ============ TITLE END ============ -->
        
        <!-- ============ STORY START ============ -->
        
        <section id="story" class="special-container">
            <div class="container ">
                <div class="row">
                  <div class="col-sm-12">
                    <br><br>
                    <h2 style="font-size: 20px;">Your privacy is critically important to us. As such, we have a few fundamental principles</h2>

                    <ol class="content-display">
                        <li>We don't ask you for personal information unless we truly need it.</li>
                        <li>We don't share your personal information with anyone except to comply with the law, develop our products, or protect our rights.</li>
                        <li>We don't store personal information on our servers unless required for the on-going operation of one of our services.</li>
                    </ol>


                    <h2 style="font-size: 20px;">Website Visitors</h2>
                    <p class="content-display">
                        Like most website operators, this website collects non-personally-identifying information of the sort that web browsers and servers typically make available, such as the browser type, language preference, referring site, and the date and time of each visitor request. Our purpose for collecting non-personally identifying information is to better understand how visitors use this website. From time to time, we may release non-personally-identifying information in the aggregate, e.g., by publishing a report on trends in the usage of its website. This website also collects potentially personally-identifying information like Internet Protocol (IP) addresses.
                    </p>

                    <h2 style="font-size: 20px;">Gathering of Personally - Identifying Information</h2>
                    <p class="content-display">
                        Certain visitors to this website choose to interact with it in ways that require the website to gather personally-identifying information. The amount and type of information that the website gathers depends on the nature of the interaction. In each case, this website collects such information only insofar as is necessary or appropriate to fulfill the purpose of the visitor's interaction. The website does not disclose personally-identifying information other than as described below. Visitors can always refuse to supply personally-identifying information, with the caveat that it may prevent them from engaging in certain website-related activities or services. 
                    </p>

                    <h2 style="font-size: 20px;">Protection of Certain Personally - Identifying Information</h2>
                    <p class="content-display">
                        This website will not rent or sell potentially personally­ identifying and personally-identifying information to anyone. 
                    </p>
                    </div>
                </div>
            </div>
        </section>
      

    </div>

</div>


