import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import{HeaderComponent} from './Shared/Components/header.component'
import { HttpClientModule } from '@angular/common/http';
import{FooterComponent} from './Shared/Components/footer.component'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HomeComponent } from './Publish/Components/home.component';
import { JobsComponent } from './Publish/Components/jobs.component';
import { AboutusComponent } from './Publish/Components/aboutus.component';
import { ContactusComponent } from './Publish/Components/contactus.component';
import { JobDetailsComponent } from './Publish/Components/job-details.component';
import { JobPostService } from './Services/job-post.service';
import { ApplyComponent } from './Publish/Components/apply.component';
import { RequestStaffingComponent } from './Publish/Components/request-staffing.component';
import { JointCommissionComponent } from './Publish/Components/joint-commission.component';
import { GlobalApplyComponent } from './Publish/Components/global-apply.component';
import { MissionComponent } from './Publish/Components/mission.component';
import { LeadershipComponent } from './Publish/Components/leadership.component';
import { SidebarComponent } from './Shared/Components/sidebar.component';
import { ProfileComponent } from './Publish/Components/profile.component';
import { RecruitmentComponent } from './Publish/Components/recruitment.component';
import { FloridaComponent } from './Publish/Components/florida.component';
import { IllinoisComponent } from './Publish/Components/illinois.component';
import { ImgComponent } from './Publish/Components/img.component';
import { PrivacyPolicyComponent } from './Publish/Components/privacy-policy.component';






const appRoutes: Routes =[
  {path:'', component: HomeComponent },
  {path:'home', component:HomeComponent },
  {path:'jobs', component:JobsComponent },
  {path:'aboutus', component:AboutusComponent},
  {path:'about-us/our-mission', component:MissionComponent},
  {path:'about-us/leadership', component:LeadershipComponent},
  {path:'about-us/recruitment', component: RecruitmentComponent },
  {path:'contactus', component:ContactusComponent},
  {path:'request-staffing', component:RequestStaffingComponent},
  {path:'job-details/:id/:title', component: JobDetailsComponent },
  {path:'profile/:id', component: ProfileComponent },  
  {path:'job/apply', component:ApplyComponent },
  {path:'compliance/standards',component:JointCommissionComponent},
  {path:'compliance/state-staffing-registrations/florida', component: FloridaComponent },
  {path:'compliance/state-staffing-registrations/illinois',component:IllinoisComponent},
  {path:'apply-now/quick-apply',component:GlobalApplyComponent},
  {path:'privacy-policy',component:PrivacyPolicyComponent},
  {path:'',redirectTo:'/home',pathMatch:'full'},
  {path:'**', component: HomeComponent }
  
];

@NgModule({
  declarations: [
    AppComponent,  
    JobsComponent,  
    HomeComponent,
    JobDetailsComponent, 
    AboutusComponent,
    ContactusComponent, 
    HeaderComponent,
    FooterComponent,
    ApplyComponent,
    RequestStaffingComponent,
    JointCommissionComponent,
    GlobalApplyComponent,
    MissionComponent,
    LeadershipComponent,
    SidebarComponent,
    ProfileComponent,
    RecruitmentComponent,
    FloridaComponent,
    IllinoisComponent,
    ImgComponent,
    PrivacyPolicyComponent
  
  ],
  imports: [
    HttpClientModule,BrowserModule,
    AppRoutingModule, FormsModule, ReactiveFormsModule, 
    RouterModule.forRoot(appRoutes)
  ],
  providers: [{provide:JobPostService}],
  bootstrap: [AppComponent]
})
export class AppModule { }

