<!-- ============ FOOTER Start ============ -->
<footer class="fixed-bottom footer">

  <div id="credits">
    <div class="container ">
      <div class="row">
        <div class="col-sm-4 footer-content" >
          &copy; {{_year}} Lifeline Healthcare Professionals Inc.
        </div>
        <div class="col-sm-3 footer-content">
          <a routerLink="/compliance/standards"><img src="assets/images/jc.png" style="width:5%;" /> Joint Commission</a>
        </div>
        <div class="col-sm-3 footer-content">
          <a routerLink="/privacy-policy">Privacy Policy</a>
        </div>
        <div class="col-sm-2 footer-content" id="social-networks" >
          <a href="https://www.facebook.com/profile.php?id=61571754958720" target="_blank">
            <i class="fa fa-facebook-square  facebook-style"></i>
          </a>
          <a href="https://www.linkedin.com/company/lifeline-healthcare-professionals-inc/?viewAsMember=true " target="_blank"><i class="fa fa-2x fa-linkedin-square social-color"></i></a>
        </div>
      </div>
    </div>
  </div>
</footer>

<!-- ============ FOOTER END ============ -->
