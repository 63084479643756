import { Component, Input, OnInit } from '@angular/core';


@Component({
  selector: 'app-florida',
  templateUrl: '../views/florida.component.html',

})
export class FloridaComponent implements OnInit {

  Img:number=0;
  constructor() { }
  ngOnInit(): void {
    window.scrollTo(0,0);
  }
  onClickView(value: number) {
   this.Img=value;
  }
}
