import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-aboutus',
  templateUrl: '../views/aboutus.component.html'
})
export class AboutusComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    
    window.scrollTo(0,0);
  
  }

}
