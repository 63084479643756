<div class="row">
  <div class="col-sm-12">
    <img alt="Featured Job" class="img-responsive" src="../../assets/images/Telemetry.jpg">
    <br>
    <h3>Contact Us</h3>
    <i class="glyphicon glyphicon-log-in"></i><strong>
        <span class="sidebar-padding">
        <a href="https://www.google.com/maps?q=4715D+Sunset+Blvd,+Lexington,+SC+29072" class="sidebar-padding-map" target="_blank">
        4715D Sunset Blvd | Lexington SC 29072</a>
            </span></strong><br>
    <i class="fa fa-phone"></i><strong class="sidebar-padding-phone"> &nbsp;&nbsp; (803)-528-4567</strong><br>

    <i class="fa fa-envelope"></i><strong>
        &nbsp;<span class="sidebar-padding">
        <a href="mailto:info@lifelinehcp.com?subject=" class="sidebar-padding-mail">info&#64;lifelinehcp.com</a>
            </span></strong><br>

  </div>
</div>
