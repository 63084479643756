<!-- ============ CONTACT START ============ -->
<section id="contact">
  <div class="container">
    <div class="row"> 
      <div class="col-sm-8">
        <img src="assets/images/LHCP-Global Location.png" alt="Global Locations" width="100%">
      </div>
      <!-- --- sidebar here--- -->
      <div class="col-sm-4" id="sidebar">
        <br>
        <app-sidebar></app-sidebar>
      </div>
      <!-- ---------sidebar end------- -->
    </div>
  </div>
</section>

<!-- ============ CONTACT END ============ -->
