<!-- ============ TITLE START ============ -->

<section id="title">
  <div class="container">
    <div class="row">
      <div class="col-sm-12 text-center">
        <h1>About Us</h1>
      </div>
    </div>
  </div>
</section>

<!-- ============ TITLE END ============ -->
<!-- ============ STORY START ============ -->

<section id="story">
  <div class="container">
    <div class="row">
      <div class="col-sm-8">
        <h4>OUR MISSION</h4>
        <br />
        <p class="content-display">
          Our goal each and every day is to provide the best in staffing for nurses, travel nurses, and Allied Health.
        </p>
        <h4>ABOUT</h4>
        <br />
        <p class="content-display">
          Our proven capabilities span several areas of Allied Health staffing as well as Nurse and Clinical staffing for long term contracts and travel positions. We strive to maintain good communication with each of our candidates so that they are well informed before accepting a position with one of our clients. Once they begin work we continue to stay in touch in order to ensure they are doing well and to discuss their next assignment. We take of our staff so they can provide the best patient care available.
        </p>
      </div>
        <!-- --- sidebar here--- -->
        <div class="col-sm-4" id="sidebar">
          <br>
          <!----<app-sidebar></app-sidebar> -->
        </div>
        <!-- ---------sidebar end------- -->
    </div>
  </div>
</section>


