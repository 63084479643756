<!-- ============ FOOTER Start ============ -->
<footer class="fixed-bottom">

    <div id="credits">
        <div class="container ">
          <div class="row">
            <div class="col-sm-4">
              &copy; 2021 Lifeline Healthcare Professionals Inc<br>
            </div>
            <div class="col-sm-3 leadership-style" >
              <a routerLink="/compliance/standards" style="color: white;"><img src="assets/images/jc.png" style="width:10%;"/> Joint Commission</a>             
              </div>
              <div class="col-sm-3 leadership-style" >
                <a routerLink="/privacy-policy" style="color: white;">Privacy Policy</a>
                </div> 
            <div class="col-sm-2" id="social-networks">                
                <a href="https://www.linkedin.com/company/lifeline-healthcare-professionals" target="_blank"><i class="fa fa-2x fa-linkedin-square social-color"></i></a>
              </div>
            </div>
          </div>
    </div>
</footer>

<!-- ============ FOOTER END ============ -->
