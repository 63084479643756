<!-- ============ CONTACT START ============ -->
<!-- <section id="contact" class="color2"> --Original tag -->
<section id="contact">
  <div class="container">
    <div class="row">
      <div class="col-sm-6">
        <iframe src="https://www.google.com/maps/d/u/0/embed?mid=1XeG9h1XfP_ylPOsKoVEg5DsRcIk5UjPC" width="100%" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
      </div>
      <!-- --- sidebar here--- -->
      <div class="col-sm-6" id="sidebar">
        <br>
        <app-sidebar></app-sidebar>
      </div>
      <!-- ---------sidebar end------- -->
    </div>
  </div>
</section>

<!-- ============ CONTACT END ============ -->
