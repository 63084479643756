export class JobsModel{
    Id:number=0;   
    
    StateId:number=0;
    CountryId:number=0;
    JobTitle:string='';
    Description:string='';
    JobType:string='';
    JobDuration:string='';
    City:string=''; 
    StateText:string=''; 
    StateShortCode:string=''; 
    Country:string='';  
}