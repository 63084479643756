import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { JobPostService } from 'src/app/Services/job-post.service';
import { StatesResponse } from 'src/app/Service Model/StatesResponse';
import { StatesModel } from 'src/app/ViewModels/StatesModel';
import { GpRequest } from 'src/app/Service Model/Staffing';
import { JobsResponse } from 'src/app/Service Model/JobsResponse';
import { JobsModel } from 'src/app/ViewModels/JobsModel';


@Component({
  selector: 'app-jobs',
  templateUrl: '../views/jobs.component.html'
  // styleUrls: ['./jobs.component.scss']
})
export class JobsComponent implements OnInit {
 
  statesResponse:StatesResponse = new StatesResponse; 
  stateModel:StatesModel[]=[];

  jobsResponse:JobsResponse = new JobsResponse;
  jobsModel:JobsModel[]=[];
  filteredJobs:JobsModel[]=[];
 
  gpRequest:GpRequest=new GpRequest();
  selectedStateLevel:any=-1;
  selectedState:number=0; 
  isLoad : boolean =false;

  constructor(private http:HttpClient,private job:JobPostService,
    private route:Router) {
   
   }

  ngOnInit(): void {
    window.scrollTo(0, 0);   
    this.fetchStates();
    this.fetchJobs();   
    
  }

  fetchStates(){
   // this.statesResponse=new StatesResponse();    
    this.gpRequest=new GpRequest();       
    this.gpRequest.Key="1";
    this.job.getStates(this.gpRequest).subscribe((x:StatesResponse)=>{
      this.statesResponse=x;
      const sList= this.statesResponse.Model;
      if(this.statesResponse.Model!=null){
       this.stateModel= this.statesResponse.Model;
       }   
      
    })     
  }

  

  //#region Display Job by State
  onChangeState(){  
    this.isLoad=true;
    this.selectedState= Number(this.selectedStateLevel.Id);    
  
    this.filteredJobs=this.jobsModel;  
    if(this.selectedState!=0){
      this.filteredJobs=this.jobsModel.filter(x=>x.StateId==this.selectedState); 
      this.isLoad=false;   
    }
    else{
      this.filteredJobs=this.jobsModel;
      this.isLoad=false;   
    }

   }
   
   //#endregion


  fetchJobs(){
    this.isLoad=true;   
    this.jobsResponse=new JobsResponse();    
     this.gpRequest=new GpRequest();      
     
     this.job.getjobs(this.gpRequest).subscribe((x:JobsResponse)=>{
       this.jobsResponse=x;       
       if(this.jobsResponse.Jobs!=null){
        this.jobsModel= this.jobsResponse.Jobs;
        this.filteredJobs=this.jobsResponse.Jobs;
        }   
        this.isLoad=false;   
     })  
    
   }
 

  goToApply(item:JobsModel){

    localStorage.setItem("ApplyJobFor",JSON.stringify(item));
    console.log(localStorage.getItem("ApplyJobFor"));  
    this.route.navigate(['/job/apply']);  
  }
 
}

