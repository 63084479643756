import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mission',
  templateUrl: '../views/mission.component.html',
  
})
export class MissionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    window.scrollTo(0,0);
  }

}
